import React, { useRef, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Cookies from 'universal-cookie';
import '../App.css';

const Header = ({}) => {
   const [loginLink, setLoginLink] = useState('https://oauth.yandex.ru/authorize?response_type=code&client_id=32a7d4b6a05448eb829b3e15eeea9542&redirect_uri=https://wb.moytop.com/login&display=popup');

   let navigate = useNavigate(); 
   const search_get = useLocation().search;
   var search_text = new URLSearchParams(search_get).get('search') ?? '';
   const search = () => {
      if (search_text != '')
         navigate('/?search='+search_text);
      else
         navigate('/');
      window.location.reload();

   }
   const changeSearchText = (event) => {
      search_text = event.target.value.replace(/(?:\r\n|\r|\n)/g,',');
   }
   
   const params = new URLSearchParams(window.location.search);
   
   const firstUpdate = useRef(true);
   
   useEffect(() => {
         if (firstUpdate.current) {
            const cookies = new Cookies();
            if (cookies.get('identity')) {
               setLoginLink('/login');
            }

            firstUpdate.current = false;
            return;
         }
   }, []);

   return (<div className="header">
            <div className="row partition">  
            
               <div className="col-lg-3 header_logo">      
                  <div className="text">WB-SEO</div> 	  
                  <div className="text_tips"><p>Поиск ключевых запросов в базе данных Вайлдберис</p></div>
               </div>  	
                  <div className="col-lg-5 search">
                     <div className="search_head">
                        <div className="search_head__title">Что ищем?</div>
                        <div className="search_head__tips">До 100 вариантов</div>
                     </div>		 
                     <div className="search_form">
                        <textarea id="search_area" name="search" onChange={changeSearchText} placeholder="iphone 12, iphone 13, iphone mini" wrap="off">{search_text}</textarea>			
                     </div>    
                  </div>
                  
                  <div className="col-lg-2 button_area"> 
                     <button type="submit" onClick={search} className="btn btn-primary">Начать поиск</button>
                  </div>  
                  <div className="col-lg-2 button_area"> 
                     <a href={loginLink} className="btn btn-primary">Личный кабинет</a>
                  </div>    
            </div>  
         </div>);
};
export default Header;